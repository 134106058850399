import React, { Component } from "react";
export default class Experience extends Component {
  constructor() {
    super();
    this.state = {
      experience: [
        {
          company: "Lekhabook Pvt. Ltd.",
          url: "http://lekhabook.com/",
          time: "December 2020 - November 2021",
          position: "Full Stack Developer",
        }
      ],
    };
  }
  render() {
    return (
      <>
        <section className="section experience">
          <div className="section__title">Experience</div>
          <div className="section__content">
            <div className="jobs">
              {this.state.experience.map((item, index) => (
                <div className="job" key={index}>
                  <div className="time-place">
                    <div className="job__company">
                      <a
                        href={item.url}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {item.company}
                      </a>
                    </div>
                    <div className="job__time">{item.time}</div>
                  </div>
                  <div className="job__position">{item.position}</div>
                </div>
              ))}
            </div>

            <a href="{{site.resume}}" target="_blank" className="arrow-link">
              View My Resume
            </a>
          </div>
        </section>
      </>
    );
  }
}
